import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import { ThemeContextProvider } from "./context/themeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/theme-icons.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

ReactDOM.render(
  <ThemeContextProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeContextProvider>, document.getElementById("root")
);
