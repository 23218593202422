import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import routes from "./constants/routesConstants";
import PatientView from "./components/Patient/view";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.PatientView} element={<PatientView />} exact />
        <Route path="*" element={<>STAVYA</>} replace />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
