import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DicomViewerCustomComponent from "../DicomViewer";
import axios from "axios";

const PatientView = () => {
  const [searchParams] = useSearchParams();
  const opdId = searchParams.get("opdId");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getPatientFiles();
  }, [opdId, token]);

  const getPatientFiles = async () => {
    let payload = {
      opd_id: opdId,
      limit: 500,
      current_page: 1,
    };

    await axios
      .post("https://softwareapi.stavyaspine.net/api/get-patient-file-detail", payload,
        {
          headers:
          {
            "Authorization": `Bearer ${token}`
          }
        })
      .then((response) => {
        if (response?.data?.status === 200) {
          if (response?.data?.data?.length > 0) {
            const fileUrls = response.data?.data
              ?.map(innerArray => innerArray?.map(item => item?.file))
              .flat();
            localStorage.setItem("patientFiles", JSON.stringify(fileUrls));
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {!isLoading && <DicomViewerCustomComponent navigate={navigate} />}
    </>
  );
};

export default PatientView;
