import React, { useCallback, useEffect, useState } from "react";

const ThemeContext = React.createContext({
  isSidebarOpen: false,
  toggleSidebar: () => {},
  showDropShadow: false,
  showDropShadowHandler: () => {},
  hideDropShadowHandler: () => {},
  reload: 0,
  reloadHandler: () => {},
});

export const ThemeContextProvider = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showDropShadow, setShowDropShadow] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (showDropShadow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showDropShadow]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    setShowDropShadow(!showDropShadow);
  };

  const showDropShadowHandler = () => {
    setShowDropShadow(true);
  };

  const hideDropShadowHandler = () => {
    setShowDropShadow(false);
  };

  const reloadHandler = useCallback(() => {
    setReload((v) => v + 1);
  }, []);

  const contextValue = {
    isSidebarOpen: isSidebarOpen,
    toggleSidebar: toggleSidebar,
    showDropShadow: showDropShadow,
    showDropShadowHandler: showDropShadowHandler,
    hideDropShadowHandler: hideDropShadowHandler,
    reload: reload,
    reloadHandler: reloadHandler,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
